import React from "react";
import { Modal } from "react-bootstrap";

function ModalBox(props) {
  return (
    <Modal
      show={props.open}
      onHide={props.handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-event"
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.ModalObject.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.ModalObject.text}
        <input
          className="form-control"
          name="number"
          min="1"
          type={props.typeButton === "input_code" ? "text" : "number"}
          placeholder={props.ModalObject.placeholder}
          value={props.inputValue}
          onChange={(e) => props.setInputValue(e.target.value)}
        />
        <div className="text-danger mt-2">{props.errorString}</div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-spin"
          onClick={() => props.onSubmit(props.inputValue)}
          disabled={props.ModalObject.disable}
        >
          Đổi
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default ModalBox;
