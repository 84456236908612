import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Buttons from "../Buttons/Buttons";
import { getConfigLuckyWheel, getUserInfo, getPlayWheel, getListEvents } from "../../apis/index";
import LuckyWheel from "./luckyWheel";
import moment from "moment";
import AppContext from "../useContext/useContext";
import ModalFixing from "../Modal/mondalFixing";
import Audio from "./Audio";
import { clearProfileUser, getProfileUser } from "../../utils/localStorage";
import ModalFailedLogin from "../Modal/modalFailed";
import { onCLoseWebView } from "../../utils/utils";
import ModalPackage from "../Modal/modalPackage";
import { BASE_URL } from "../../constants";

function Event() {
  const { totalTurn, setTotalTurn, current_point, setCurrentPoint, playing_type, setPlayigStyle } =
    useContext(AppContext);
  const { id } = useParams();
  const [user] = useState(getProfileUser());
  const location = useLocation();
  const navigate = useNavigate();
  const event_code_state = location?.state?.data;
  const today = moment().format("YYYY-MM-DD HH:mm:ss");
  const [pointPerChange, setPointPerChange] = useState(null);
  const [audioSrc, setAudioSrc] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [listEventLength, setListEventLength] = useState(0);
  const [eventTime, setEventTime] = useState("");
  const [color, setColor] = useState("");
  const [inEventTime, setInEventTime] = useState(false);
  const refAudio = useRef(null);
  const audio = refAudio.current;
  const [openModalFixing, setOpenModalFixing] = useState(false);
  const [openFailedLogin, setOpenFailedLogin] = useState(false);
  const [openModalPackage, setOpenModalPackage] = useState(false);
  const [firstEventTime, setFirstEventTime] = useState(false);
  // state Wheel's Component
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [prevPrize, setPrevPrize] = useState(0);
  const [prizeText, setPrizeText] = useState("");
  const [prizeImage, setPrizeImage] = useState("");
  const [outOfGifts, setOutOfGifts] = useState(false);
  const [title, setTitle] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [errorTurn, setErrorTurn] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [statusWheel, setStatusWheel] = useState(null);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([
    { option: "Giải nhất", style: { backgroundColor: "green" } },
    { option: "2", style: { backgroundColor: "green" } },
    { option: "3", style: { backgroundColor: "green" } },
    { option: "4", style: { backgroundColor: "green" } },
  ]);

  const checkEventTime = () => {
    if (today >= startDate && today <= endDate) {
      return (
        setEventTime(
          <>
            Sự kiện đang diễn ra <br />
            {`${moment(startDate).format("HH:mm DD/MM/YYYY")} - ${moment(endDate).format("HH:mm DD/MM/YYYY")}`}
          </>
        ),
        setColor("text-success"),
        setInEventTime(true)
      );
    }
    if (today < startDate) {
      return (
        setEventTime(
          <>
            Sự kiện bắt đầu
            <br />
            {moment(startDate).format("HH:mm DD/MM/YYYY")}
          </>
        ),
        setColor("text-success"),
        setInEventTime(false)
      );
    }
    if (today > endDate) {
      return setEventTime("Sự kiện đã kết thúc"), setColor("text-danger"), setInEventTime(false);
    }
  };

  const checkLength = (length) => {
    switch (length) {
      case length <= 4:
        return 1.3;
      case length < 4 && length <= 8:
        return 1;
      case length > 8:
        return 0.8;
      default:
        return 1;
    }
  };
  const handleOpenModalPackage = () => {
    setOpenModalPackage(!openModalPackage);
    window.location.href = `${BASE_URL}/membership_package_expired.html`;
  };
  const handleConfigState = async () => {
    const { data: listEvent } = await getListEvents();

    const data = await getConfigLuckyWheel(id);
    if (data.success) {
      const segmentLength = data.data.segments.length;
      setData(
        data.data.segments.map((item) => {
          item.image = {
            uri: item.image,
            sizeMultiplier: checkLength(segmentLength),
            offsetX: 1,
            offsetY: 1,
          };
          return item;
        })
      );
      setPointPerChange(data.data.points_per_exchange);
      setTitle(data.data.event_name);
      setIsFetching(false);
      setStatusWheel(data.data.event_status);
      setAudioSrc(data.data.list_bg_music[0]);
      setStartDate(moment(new Date(data.data.start_date)).format("YYYY-MM-DD HH:mm:ss"));
      setEndDate(moment(new Date(data.data.end_date)).format("YYYY-MM-DD HH:mm:ss"));
      setListEventLength(listEvent.filter((event) => event.group_name === "lucky_wheel").length);
    }
    if (user && firstEventTime === false) {
      const data = await getUserInfo(id, user?.token_user);
      if (!data.success) setOpenModalPackage(true);
      setFirstEventTime(true);
      setTotalTurn(data.data.play_total);
      setCurrentPoint(data.data.point_user);
      setPlayigStyle(data.data.type_play);
    }
  };
  const checkEventConfig = async () => {
    const config = await getListEvents();
    const event = config.data.find((event) => event.id === Number(id));
    if (!event) setOpenModalFixing(true);
    else {
      await handleConfigState();
      try {
        const data = await getPlayWheel(id, event_code_state?.code, user?.token_user);
        if (data.success) {
          setTimeout(() => {
            setMustSpin(true);
            setIsSpinning(true);
            audio.play();
          }, 1000);
        }
        await handleRewards(data.data);
        await handleConfigState();
      } catch {
        clearProfileUser();
        setOpenFailedLogin(true);
      }
    }
  };

  useEffect(() => {
    handleConfigState();
  }, [user, id]);
  useEffect(() => {
    checkEventTime();
  }, [data]);

  const handleRewards = (data) => {
    if (data.error) setOutOfGifts(true);
    else {
      setPrizeNumber(data.prize_index);
      setPrizeText(data.prize_name);
      setPrizeImage(data.image);
      setTotalTurn(data.play_total);
      setIsSpinning(true);
    }
  };
  // action spin wheel function

  const handleSpinClick = async () => {
    await checkEventConfig();
  };

  return (
    <div className="container">
      <div className="cont-wrap w-cntn scroll-y">
        <Audio
          title={title}
          eventTime={eventTime}
          src={audioSrc}
          refAudio={refAudio}
          color={color}
          listEventLength={listEventLength}
        />
        {user?.id ? (
          <div className="row d-flex justify-content-around align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 lst-inf mt-1 order-1">
              {event_code_state?.code_status && !totalTurn ? (
                <div className=" clr-red fs-6 fw-semibold">Bạn đã hết lượt tham gia sự kiện</div>
              ) : (
                <div className="mb-1">
                  <div>Số lượt chơi đang có: </div>
                  <div className="clr-red fs-5 fw-semibold">{totalTurn || 0} lượt</div>
                </div>
              )}
              {playing_type === "type_1" && (
                <div className="mb-0">
                  <div>Số điểm đang có:</div>
                  <div className="clr-red fs-5 fw-semibold">{current_point} điểm</div>
                </div>
              )}
              <Buttons pointPerChange={pointPerChange} handleSpinClick={handleSpinClick} />
            </div>
            <LuckyWheel
              totalTurn={totalTurn}
              audio={audio}
              inEventTime={inEventTime}
              handleSpinClick={handleSpinClick}
              mustSpin={mustSpin}
              prizeNumber={prizeNumber}
              prevPrize={prevPrize}
              prizeText={prizeText}
              prizeImage={prizeImage}
              errorTurn={errorTurn}
              isSpinning={isSpinning}
              setMustSpin={setMustSpin}
              setIsSpinning={setIsSpinning}
              setPrevPrize={setPrevPrize}
              data={data}
              isFetching={isFetching}
              setShow={setShow}
              show={show}
              outOfGifts={outOfGifts}
              user={user}
            />
          </div>
        ) : (
          <div className="row d-flex justify-content-center align-items-center">
            <div className=" d-flex justify-content-center">
              <h5 className="mb-0 clr-red fw-normal mb-2">Bạn chưa đăng nhập</h5>
            </div>
            <LuckyWheel totalTurn={totalTurn} data={data} />
          </div>
        )}
      </div>
      <ModalFixing open={openModalFixing} setOpen={setOpenModalFixing} />
      <ModalPackage open={openModalPackage} setOpen={handleOpenModalPackage} />
      <ModalFailedLogin open={openFailedLogin} onNavigateToRoot={() => onCLoseWebView()} />
    </div>
  );
}
export default Event;
