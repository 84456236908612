import { DOMAIN } from "../constants/index";

const profile_url = () => `${DOMAIN}/api/v2/account/profile`;
export const getProfile = async (user_id) => {
  const profile = await fetch(profile_url(), {
    method: "GET",
    headers: {
      Authorization: user_id,
    },
  });
  return profile.json();
};

export const url_history_playing = (id) =>
  `${DOMAIN}/api/v2/mini_events/event_lucky_wheel/play_histories?mini_event_id=${id}`;
export const getListHistory = (id, token) => {
  return fetch(url_history_playing(id), {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });
};

export const url_config_lucky_wheel = (id) =>
  `${DOMAIN}/api/v2/mini_events/event_lucky_wheel/config_lucky_wheel?mini_event_id=${id}`;
export const getConfigLuckyWheel = (id) => {
  return fetch(url_config_lucky_wheel(id), {
    method: "GET",
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });
};

export const url_exchange_points = (id) =>
  `${DOMAIN}/api/v2/mini_events/event_lucky_wheel/exchange_point?mini_event_id=${id}`;
export const postExchangePoints = (id, token, params) => {
  try {
    return fetch(url_exchange_points(id), {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        number_of_changed: parseInt(params),
        exchange_type: "point",
      }),
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    });
  } catch {
    console.log(error);
  }
};

export const postExchangeCode = (id, token, params) => {
  return fetch(url_exchange_points(id), {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code: params, exchange_type: "code" }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });
};

export const url_user_info = (id) =>
  `${DOMAIN}/api/v2/mini_events/event_lucky_wheel/current_play_info?mini_event_id=${id}`;
export const getUserInfo = (id, token) => {
  return fetch(url_user_info(id), {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Error!");
    }
    return response.json();
  });
};

export const url_play_wheel = (id, code) =>
  `${DOMAIN}/api/v2/mini_events/event_lucky_wheel/play_lucky_wheel?mini_event_id=${id}&event_code=${
    code ?? ""
  }`;
export const getPlayWheel = (id, code, token) => {
  return fetch(url_play_wheel(id, code), {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Error!");
    }
    return response.json();
  });
};

export const url_list_events = `${DOMAIN}/api/v2/mini_events/list_event?play_type=real_play`;
export const getListEvents = () => {
  return fetch(url_list_events, {
    method: "GET",
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Error!");
    }
    return response.json();
  });
};
