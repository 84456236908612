import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { postExchangePoints, postExchangeCode } from "../../apis/index";
import { getUserInfo } from "../../apis/index";
import ModalBox from "../Modal/modal";
import AppContext from "../useContext/useContext";
import { getProfileUser, clearProfileUser } from "../../utils/localStorage";
import ModalFailedLogin from "../Modal/modalFailed";
import { onCLoseWebView } from "../../utils/utils";
function Buttons(props) {
  const {
    totalTurn,
    setTotalTurn,
    setCurrentPoint,
    playing_type,
    aToken,
    current_point,
  } = useContext(AppContext);
  const { id } = useParams();
  const [user] = useState(getProfileUser());
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openFailedLogin, setOpenFailedLogin] = useState(false);
  const [typeButton, setTypeButton] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [whichButton, setWhichButton] = useState("");
  const [errorString, setErrorString] = useState("");
  const turnCanChange = Math.floor(current_point / props.pointPerChange);

  let ModalObject = {
    title: "",
    placeholder: "",
    text: "",
    disable: false,
  };
  switch (typeButton) {
    case "input_code":
      ModalObject = {
        title: "Nhập mã code",
        placeholder: "Nhập mã code của bạn",
        text: "",
        disable: false,
      };
      break;
    case "exchange_points":
      ModalObject = {
        title: "Đổi điểm thưởng",
        placeholder: "Nhập số lượt muốn đổi",
        text: (
          <div className="text-success">
            <p>{props.pointPerChange} điểm có thể đổi một lượt quay</p>
            <p>Bạn có thể đổi {turnCanChange} lượt</p>
          </div>
        ),
        disable: turnCanChange ? false : true,
      };
      break;
    default:
      break;
  }
  const checkValidTurn = (turn) => {
    let rule = /^[1-9][0-9]*$/g;
    if (parseInt(turn) > turnCanChange) {
      return "Bạn không đủ điểm thưởng";
    }
    if (!turn.match(rule)) {
      return "Số lượt không hợp lệ";
    }
    if (turn.match(rule)) {
      return "";
    }
  };
  const handleShowModal = (type) => {
    setTypeButton(type);
    setOpen(true);
    setWhichButton(type);
  };
  const resetState = () => {
    setOpen(false);
    setTypeButton("");
    setInputValue("");
    setErrorString("");
  };
  const onSubmit = async (turn) => {
    try {
      if (whichButton === "exchange_points") {
        if (checkValidTurn(turn) == "") {
          await postExchangePoints(id, user.token_user, turn);
          await setCurrentPoint(
            (prevPoint) =>
              parseInt(prevPoint) - parseInt(turn * props.pointPerChange)
          );
          setTotalTurn((prevTurn) => Number(prevTurn) + Number(turn));
          resetState();
          getUserInfo(id, user.token_user).then(() => {
            // props.handleSpinClick();
          });
        } else {
          setErrorString(checkValidTurn(turn));
        }
      } else {
        await postExchangeCode(id, user.token_user, turn).then((data) => {
          if (!data.success) {
            return setErrorString("Mã code không hợp lệ");
          }
          if (data.success) {
            setTotalTurn((prevTurn) => Number(prevTurn) + 1);
            resetState();
            // props.handleSpinClick();
          }
        });
      }
    } catch {
      clearProfileUser();
      setOpen(false);
      setOpenFailedLogin(true);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setInputValue("");
    setErrorString("");
  };
  const moveToHistory = () => {
    navigate("/history-playing");
  };


  return (
    <div className="row mt-3  d-flex justify-content-center ">
      {playing_type === "type_1" && (
        <>
          <div className=" mt-2">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => handleShowModal("exchange_points")}
            >
              <div className="btn-txt">
                <span className="fw-semibold">Đổi Điểm</span>
                <span className="btn-style"></span>
              </div>
              <span className="arrow"></span>
            </button>
          </div>

          <div className=" mt-2">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => handleShowModal("input_code")}
            >
              <div className="btn-txt">
                <span className="fw-semibold">Nhập Code</span>
                <span className="btn-style"></span>
              </div>
              <span className="arrow"></span>
            </button>
          </div>
        </>
      )}
      <Link to="/history-playing" state={id}>
        <div className=" mt-2">
          <button type="button" className="btn btn-success">
            <div className="btn-txt">
              <span className="fw-semibold">Lịch sử chơi</span>
              <span className="btn-style"></span>
            </div>
            <span className="arrow"></span>
          </button>
        </div>
      </Link>
      <a
        className="mt-2 mb-3 clr-primary text-decoration-underline"
        href="https://help.hainong.vn/muc/13/"
        target="_blank"
      >
        Hướng dẫn, thể lệ
      </a>

      <ModalBox
        open={open}
        handleCloseModal={handleCloseModal}
        inputValue={inputValue}
        setInputValue={setInputValue}
        onSubmit={onSubmit}
        pointPerChange={props.pointPerChange}
        turnCanChange={turnCanChange}
        errorString={errorString}
        ModalObject={ModalObject}
        typeButton={typeButton}
      />
      <ModalFailedLogin
        open={openFailedLogin}
        onNavigateToRoot={() => onCLoseWebView()}
      />
    </div>
  );
}
export default Buttons;
