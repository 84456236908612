import React from 'react'
import OutOfGift from "../../assets/images/empty-box.png"
import { Modal } from "react-bootstrap";

const ModalOutOfGift = (props) => {
  const { open, handleClose } = props;
  return (
    <Modal show={open} onHide={handleClose} centered className="modal-event fixing">
      <Modal.Body>
        <div className="p-2 m-3 ">
          <div className="d-flex justify-content-center ">
            <img src={OutOfGift} className="sized-img" />
          </div>
          { <div className="text-center mt-4">
            <div className="text-success text-center genos-regular">
              <p className="fs-5 fw-regular mb-0">Quà tặng đã hết</p>
              <p className="fs-5 fw-regular mb-0">Chúng tôi xin lỗi vì sự bất tiện này</p>
            </div>
          </div> }
        </div>
      </Modal.Body>
      <span className="close-btn" onClick={handleClose} />
    </Modal>
  );
}

export default ModalOutOfGift
