var DOMAIN_ENV = "";
var CALLBACK_URL_ENV = "";
var id_portal = "";
var hainong_url = "";
var luckywheel_url = "";

if (process.env.NODE_ENV === "none") {
  DOMAIN_ENV = "https://dev.panel.hainong.vn";
  CALLBACK_URL_ENV = "http://localhost:3000";
  id_portal = "https://dev.id.hainong.vn";
  hainong_url = "https://dev.hainong.vn/";

}

if (process.env.NODE_ENV === "production") {
  DOMAIN_ENV = "https://panel.hainong.vn";
  CALLBACK_URL_ENV = "https://beta.hainong.vn";
  id_portal = "https://id.hainong.vn";
  hainong_url = "https://beta.hainong.vn/";
  luckywheel_url= "https://luckywheel.event.hainong.vn/"
}

if (process.env.NODE_ENV === "development") {
  DOMAIN_ENV = "https://dev.panel.hainong.vn";
  CALLBACK_URL_ENV = "https://dev.hainong.vn";
  id_portal = "https://dev.id.hainong.vn";
  hainong_url = "https://dev.hainong.vn/";
  luckywheel_url= "https://dev.luckywheel.event.hainong.vn/"
}

if (process.env.NODE_ENV === "staging") {
  DOMAIN_ENV = "https://staging.panel.hainong.vn";
  CALLBACK_URL_ENV = "https://staging.hainong.vn";
  id_portal = "https://staging.id.hainong.vn";
  hainong_url = "https://staging.hainong.vn/";
  luckywheel_url= "https://staging.luckywheel.event.hainong.vn/"
}

if (process.env.NODE_ENV === "uat") {
  DOMAIN_ENV = "https://uat.panel.hainong.vn";
  CALLBACK_URL_ENV = "https://uat.hainong.vn";
  id_portal = "https://uat.id.hainong.vn";
  hainong_url = "https://uat.hainong.vn/";
  luckywheel_url= "https://uat.luckywheel.event.hainong.vn/"

}

export const DOMAIN = DOMAIN_ENV;
export const CALLBACK_URL = CALLBACK_URL_ENV;
export const SSO_ENDPOINT = id_portal;
export const HAINONG_URL = hainong_url;
export const BASE_URL = luckywheel_url;
