import React, { useState } from "react";
import Mute from "../../assets/images/mute.png";
import Unmute from "../../assets/images/unmute.png";
import { useNavigate } from "react-router-dom";

const Audio = (props) => {
  const navigate = useNavigate();
  const { title, eventTime, src, refAudio, color, listEventLength } = props;
  const [muted, setMuted] = useState(false);

  function handleClick() {
    navigate(-1);
  }
  const controlAudio = () => {
    if (muted === false) setMuted(true);
    else setMuted(false);
  };

  const handleShowEvenTime = () => {
    return (
      <>
        <div className={`d-flex justify-content-center fs-6 ${color} fw-semibold font-italic`}>{eventTime}</div>
      </>
    );
  };

  return (
    <div className="header d-flex align-items-center justify-content-center mb-1">
      <div className="text-center mb-3">
        <div className="position-relative" style={{ padding: "0 40px" }}>
          {listEventLength > 1 && (
            <div
              className="position-absolute"
              style={{ fontSize: "45px", top: "-30px", left: "10px" }}
              onClick={handleClick}>
              &lsaquo;
            </div>
          )}
          <h3 className="mt-3 ml-3 text-uppercase fw-bold mb-1">{title}</h3>
          <div className="speaker-btn position-absolute audio-btn">
            <button className="" onClick={() => controlAudio()}>
              <img src={muted ? Mute : Unmute} />
            </button>
            <div>
              <audio ref={refAudio} src={src} muted={muted} />
            </div>
          </div>
        </div>
        {handleShowEvenTime()}
      </div>
    </div>
  );
};

export default Audio;
