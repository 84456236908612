import React from "react";

import { Modal } from "react-bootstrap";

const ModalPackage = (props) => {
  const { open, setOpen } = props;
  return (
    <Modal show={open} onHide={setOpen} centered className="modal-event fixing">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="p-2 ">
          <div className="text-center mt-4">
            <div className="text-success text-center genos-regular">
              <p>Tài khoản của bạn cần đăng ký gói cước để sử dụng tính năng này</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <span className="close-btn" onClick={setOpen} />
    </Modal>
  );
};

export default ModalPackage;
