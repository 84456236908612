import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getListHistory } from "../../apis/index";
import moment from "moment";
import { getProfileUser } from "../../utils/localStorage";

function HistoryPlaying() {
  const [user] = useState(getProfileUser());
  const location = useLocation();
  const navigate = useNavigate();
  let [dataHistories, setDataHistories] = useState([]);
  function handleClick() {
    navigate(-1);
  }

  useEffect(() => {
    getListHistory(location?.state, user.token_user).then((data) => setDataHistories(data.data));
  }, [user]);
  const translatePlayType = (playType) => {
    switch (playType) {
      case "free":
        return "Miễn phí";
      case "cost":
        return "Trả phí";
      case "code":
        return "Mã Code";
      case "event_code":
        return "Mã sự kiện";
      default:
        return false;
    }
  };
  return (
    <div className="cont-wrap w-cntn p-0 scroll-y">
      <div className="row d-flex justify-content-between align-items-center text-center mt-2 mb-3">
        <div className="title d-flex align-items-center" onClick={handleClick}>
          <span className="fs-2 icon-return px-3">&lsaquo;</span>
          <span className="mt-1 fs-5 text-uppercase fw-bold">Lịch Sử Chơi</span>
        </div>
      </div>
      {dataHistories.length > 0 ? (
        <div className="overflow-xs">
          <div className="row d-flex  text-center align-items-center justify-content-between">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col" className="align-middle">
                    Thời gian
                  </th>
                  <th scope="col" className="align-middle">
                    Giải thưởng
                  </th>
                  <th scope="col" className="align-middle">
                    Cách thức
                  </th>
                </tr>
              </thead>
              <tbody className="list-history">
                {dataHistories.map(function (data, index) {
                  return (
                    <tr key={index}>
                      <td className="align-middle data-history">{moment(data.created_at).format("DD/MM/YYYY")}</td>
                      <td className="align-middle data-history">{data.prize_name}</td>
                      <td className="align-middle data-history">{translatePlayType(data.play_type) ?? "Miễn phí"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center fs-4 mt-3"> Bạn chưa tham gia trò chơi</div>
      )}
    </div>
  );
}
export default HistoryPlaying;
