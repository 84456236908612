import React from "react";

import { Modal } from "react-bootstrap";

const ModalFailedLogin = (props) => {
  const { open, onNavigateToRoot } = props;
  return (
    <Modal
      show={open}
      onHide={onNavigateToRoot}
      centered
      className="modal-event fixing"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="p-2 ">
          <div className="text-center mt-4">
            <div className="text-success text-center genos-regular">
              <p>Tài khoản của bạn đã đăng nhập trên thiết bị khác</p>
              <p>Vui lòng đăng nhập lại</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <span className="close-btn" onClick={onNavigateToRoot} />
    </Modal>
  );
};

export default ModalFailedLogin;
