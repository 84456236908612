import React from "react";
import { Wheel } from "react-custom-roulette";
import ModalReward from "../Modal/modalReward";
import Loader from "../Loader";
import ModalOutOfGift from "../Modal/modalOutOfGift";

const LuckyWheel = (props) => {
  const {
    totalTurn,
    audio,
    inEventTime,
    handleSpinClick,
    mustSpin,
    prizeNumber,
    prevPrize,
    prizeText,
    prizeImage,
    errorTurn,
    isSpinning,
    setMustSpin,
    setIsSpinning,
    setPrevPrize,
    isFetching,
    data,
    setShow,
    show,
    outOfGifts,
    user,
  } = props;

  const handleClose = () => setShow(false);
  const check = totalTurn <= 0;
  return (
    <>
      <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 order-2">
        <script type="application/javascript" src="/fastClick.js"></script>
        {isFetching ? (
          <div className="d-flex justify-content-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="wheel mt-2" id="lucky-wheel">
              <Wheel
                className="test"
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                data={data}
                textColors={["#fff"]}
                textDistance={70}
                outerBorderColor="#fff"
                radiusLineColor="#d5d5d5"
                innerBorderWidth={0}
                radiusLineWidth={0.5}
                startingOptionIndex={prevPrize}
                spinDuration={0.6}
                disableInitialAnimation={true}
                fontSize={[17]}
                onStopSpinning={() => {
                  setShow(true);
                  audio.pause();
                  setIsSpinning(false);
                  setMustSpin(false);
                  setPrevPrize(prizeNumber);
                }}
              />
              {user && check === false && inEventTime === true && (
                <button
                  className="center-button"
                  onClick={() => {
                    handleSpinClick(), setIsSpinning(true);
                  }}
                  disabled={isSpinning}></button>
              )}
            </div>
            {user && check === false && inEventTime === true && (
              <div className=" mt-2 d-flex justify-content-center align-items-center">
                <button
                  className="btn-spin"
                  onClick={() => {
                    handleSpinClick(), setIsSpinning(true);
                  }}
                  disabled={isSpinning}
                  style={{ marginRight: "10px" }}>
                  Chơi ngay
                </button>
              </div>
            )}
          </>
        )}

        {outOfGifts ? (
          <ModalOutOfGift open={show} handleClose={handleClose} />
        ) : (
          <ModalReward
            show={show}
            handleClose={handleClose}
            prizeText={prizeText}
            errorTurn={errorTurn}
            prizeImage={prizeImage}
          />
        )}
      </div>
    </>
  );
};

export default LuckyWheel;
