import React from "react";
import logo from "../../assets/images/logo01.png";

function NavBar() {
  return (
    <div className="top-nav">
      <div className="container">
        <div className="row d-flex align-items-center text-white justify-content-end">
          <div className="d-flex justify-content-between align-items-center w-cntn">
            <a href="/">
              <div className="logo-image">
                <img src={logo} alt="logo" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default NavBar;
