import React from "react";
import { Modal } from "react-bootstrap";
import Fixing from "../../assets/images/repairing.jpg";
import { useNavigate } from "react-router-dom";

const ModalFixing = (props) => {
  const navigate = useNavigate();
  const { open, setOpen } = props;
  const handleClose = () => {
    setOpen(false), navigate("/");
  };

  return (
    <Modal show={open} onHide={handleClose} centered className="modal-event fixing">
      <Modal.Body>
        <div className="p-2 m-3 ">
          <div className="d-flex justify-content-center ">
            <img src={Fixing} className="sized-img" />
          </div>
          {/* <div className="text-center mt-4">
            <div className="text-success text-center genos-regular">
              <p className="fs-5 fw-regular mb-0">Sự kiện đang bảo trì</p>
              <p className="fs-5 fw-regular mb-0">Chúng tôi xin lỗi vì sự bất tiện này</p>
            </div>
          </div> */}
        </div>
      </Modal.Body>
      <span className="close-btn" onClick={handleClose} />
    </Modal>
  );
};

export default ModalFixing;
