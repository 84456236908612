import React from "react";
import { Modal } from "react-bootstrap";

function ModalCode(props) {
  const { open, setOpen, code, setCode, onSubmit, error, setError } = props;

  const resetState = () => {
    setCode("");
    setOpen(false);
    setError("");
  };

  const handleCloseModal = (e) => resetState();

  return (
    <Modal
      show={open}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-event"
    >
      <Modal.Header closeButton>
        <Modal.Title>Tham gia sự kiện</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          className="form-control"
          type="text"
          placeholder="Nhập mã tham gia sự kiện"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <div className="text-danger mt-2">{error}</div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-spin"
          onClick={() => onSubmit(code)}
        >
          Xác nhận
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default ModalCode;
